import React, { Component } from 'react';

import './explore.css';

import { isMobile } from '../../../utils/AppUtils';

export default ({onScrollHandler}) =>
    <div id="home" className="explore-main-container">
        <div className="explore-p-container">
            <p>ONE ROOF MANY SOLUTIONS</p>
            <div className="explore-explore-button-container">
                <div className="explore-explore-button" onClick={onScrollHandler}>Explore Now</div>
            </div>
        </div>
        <div className="explore-img-container">
            <img src={isMobile() ? "https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2Fback-building.png?alt=media&token=00439fe7-20a3-49c0-8848-9c68300afa35":"https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2Fbg-group.png?alt=media&token=fe3e8007-27e7-4824-96ef-66609bdfff0e" } />
        </div>
    </div>