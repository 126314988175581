import React, { Component } from 'react';

import './home.css';

import Explore from '../explore/Explore';
import Products from '../products/Products';
import Scroller from '../scroller/Scroller';
import Promo from '../promo/Promo';
import Support from '../support/Support';

export default class Home extends Component {

  render() {
    return (
      <div>
        <Explore onScrollHandler={() => { this.prodRef.scrollIntoView({ behavior: 'smooth' }) }} />
        <div ref={(ref) => this.prodRef = ref}></div>
        <Products />
        <Scroller />
        <Promo />
        <Support />
      </div>
    );
  }
}
