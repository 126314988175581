import React, { Component } from 'react';

import './promo.css';

import { isMobile } from '../../../utils/AppUtils';

export default () => <div className="promo-main-container">

    <div className={`promo-sub-main-container ${isMobile() ? "row" : ""}`}>

        <div className={`promo-img-container  ${isMobile() ? "col-sm-6" : ""}`}>
            <img src={"https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2Ffront-page-wire.png?alt=media&token=5b457a03-3d5d-4b19-9433-95c651a1c8ff"} />
        </div>

        <div className={`promo-text-container ${isMobile() ? "col-sm-6" : ""}`}>
            <p>
                "Zoom in the Ultimate Protection, Get wired to Safety! Nishad Wires and Cables business unit is a leading manufacturer of wires and cables catering to the need of domestic as well as Industrial needs."
        </p>
        </div>

        <div>

        </div>

    </div>
</div>