import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import NavigationBar from './modules/common/navigationBar/NavigationBar';
import BottomBar from './modules/common/bottomBar/BottomBar';
import Routes from "./App.routes.js";

function App() {
  return (
    <BrowserRouter>
      <div>
        <NavigationBar />
        <Routes/>
        <BottomBar/>
      </div>
    </BrowserRouter>
  );
}

export default App;
