import React from 'react';

import './navigationBar.css';

export default () =>

  <nav className="navbar navbar-expand-md bg-light navbar-light navbar-back-color">
    {/* <!-- Brand --> */}
    <a className="navbar-brand navbar-image-container" href="#">
      <div className="navbar-logo-container">

        <div className="navbar-prod-container">
          <img src={"https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2Frainbow.png?alt=media&token=570b1efe-ecd2-4c4f-b59e-66fc24516ff1"} className="navbar-prod-icon" />
          <p className="navbar-prod-name">HEW</p>
        </div>

        <div className="navbar-brand-container">
          <p className="navbar-brand-name">NISHAD</p>
          <p className="navbar-brand-tagline">Vishwas Ka Prateek</p>
        </div>


      </div>

    </a>

    {/* <!-- Toggler/collapsibe Button --> */}
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span className="navbar-toggler-icon"></span>
    </button>

    {/* <!-- Navbar links --> */}
    <div className="collapse navbar-collapse nav-bar-items" id="collapsibleNavbar">
      <ul className="navbar-nav">
        <li className="nav-item home">
          <p className="nav-link nav-menu-link nav-active-item">Home</p>
        </li>
        <li className="nav-item products">
          <p className="nav-link nav-menu-link" onClick={() => document.getElementById('products').scrollIntoView({ behavior: 'smooth' })}>Products</p>
        </li>
        <li className="nav-item support">
          <p className="nav-link nav-menu-link" onClick={() => document.getElementById('support').scrollIntoView({ behavior: 'smooth' })}>Support</p>
        </li>
      </ul>
    </div>
  </nav>
