import React, { Component } from 'react';

import './scroller.css';

export default () => <div className="scroller-main-container">
    <div className="parallax">
        <div className="scroller-text-container">
            <p> Make your life happier with NISHAD</p>
        </div>
    </div>
</div>