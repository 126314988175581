import React from 'react';

import './support.css';


export default () =>
    <div id="support" className="support-main-container">

        {/* tag line */}
        <div className="support-tagline-content-container">
            <p>Customer Service</p>
        </div>

        {/* container */}
        <div className="support-main-content-container row">

            <div className="support-content-container">
                <div>

                    <img src={"https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2Fphone-call.png?alt=media&token=60cea3cc-c0d1-4ebd-b74d-57152cdf3937"} style={{ height: 48, width: 48, marginBottom: 36 }} />
                    <h5>Speak To Us</h5>
                    <p className="support-content-container-ptag">+91-144-2345325</p>
                </div>

            </div>
            <div className="support-content-container">
                <div>

                    <img src={"https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2Fmail.png?alt=media&token=e3e36d43-9003-4bbf-8ca0-6187c137a5cd"} style={{ height: 48, width: 48, marginBottom: 36 }} />
                    <h5>Write to Us</h5>
                    <p className="support-content-container-ptag">nishadelectricalindia@gmail.com</p>
                </div>

            </div>

        </div >

    </div >
