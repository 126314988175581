export const isMobile = () => {
    if (window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.IS_MOBILE) {
      return true;
    }
  
    const mobileCheck = {
      Android: () => navigator.userAgent.match(/Android/i),
      BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
      iOS: () => navigator.userAgent.match(/iPhone/i),
      Opera: () => navigator.userAgent.match(/Opera Mini/i),
      Windows: () => navigator.userAgent.match(/IEMobile/i),
      detectmob: () => window.innerWidth <= 799,
      any: () =>
        mobileCheck.Android() ||
        mobileCheck.BlackBerry() ||
        mobileCheck.iOS() ||
        mobileCheck.Opera() ||
        mobileCheck.Windows() ||
        mobileCheck.detectmob()
    };
    return mobileCheck.any() ? true : false;
  };