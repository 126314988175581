import React, { Component } from 'react';
import {Route} from 'react-router-dom';
import Home from './modules/pages/home/Home';
import './App.css'



export default () => <div className="main-container">
     <Route path={'/'} exact render={() => <Home />} />

</div>
