import React from 'react';

import './bottomBar.css'

export default () =>
    <div className="app-footer row">

        {/* address */}
        <div className="col-sm-4 app-footer-sub-container">
               {/* follow us on social links */}
        <div>
            <h5>Follow us on</h5>
            <div className="app-footer-container ">
                <a href={"https://www.facebook.com/nishadelectricals/"}>
                    <img src={"https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2Ffacebook.png?alt=media&token=0a4ff22f-a9e1-41fc-b927-0c752cf2d7e7"} style={{ height: 36, width: 36, resizeMode: 'cover',marginRight:20 }} />
                </a>
                <a className="app-footer-social-icon-anchor-container" href={"https://www.instagram.com/nishadelecticalindia/"}>
                    <img src={"https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2Finstagram.png?alt=media&token=1074796b-7067-41d6-849a-51150ba169c8"} style={{ height: 36, width: 36, resizeMode: 'cover',marginRight:24 }} />
                </a>
                <a className="app-footer-social-icon-anchor-container" href={"https://twitter.com/nishad_india"}>
                    <img src={"https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2Ftwitter.png?alt=media&token=87eea056-ce7e-45bd-a801-a9bd10532557"} style={{ height: 36, width: 36, resizeMode: 'cover' }} />
                </a>
            </div>
        </div>
        <div className="app-footer-register">

            <h5>Registered Office</h5>
            <div className="app-footer-container">
                <p>57, Jai Complex<br />near road no-2 <br />Alwar(Raj.)<br /><span style={{ fontWeight: '500' }}>PINCODE:</span> 301001</p>
            </div>
        </div>

        </div>

        {/* products */}
        <div className="col-sm-4 app-footer-sub-container">
            <h5>Products</h5>
            <div className="app-footer-container ">
                <div className="app-footer-content-container"> <p className="bottom-menu-link" >Wires & Cables</p></div>
                <div className="app-footer-content-container"> <p className="bottom-menu-link" >Electric Pipes</p></div>
                <div className="app-footer-content-container"> <p className="bottom-menu-link" >Bands</p></div>
                <div className="app-footer-content-container"> <p className="bottom-menu-link" >M.C.B</p></div>
                <div className="app-footer-content-container"> <p className="bottom-menu-link" >Immersion Rod</p></div>
                <div className="app-footer-content-container"> <p className="bottom-menu-link" >L.E.D Pannels</p></div>
            </div>
        </div>


        {/* explore */}
        <div className="col-sm-4 app-footer-sub-container">
            <h5>Explore</h5>
            <div className="app-footer-container ">
                <div className="app-footer-sub-content-container"> <p className="bottom-menu-link"onClick={()=>document.getElementById('home').scrollIntoView({behavior: 'smooth'})}>Home</p></div>
                <div className="app-footer-sub-content-container">  <p className="bottom-menu-link"onClick={()=>document.getElementById('products').scrollIntoView({behavior: 'smooth'})}>Products</p></div>
                <div className="app-footer-sub-content-container"><p className="bottom-menu-link" onClick={()=>document.getElementById('support').scrollIntoView({behavior: 'smooth'})}>Support</p></div>
            </div>
        </div>

     
    </div>
