import React, { Component } from 'react';

import './products.css';

let wireImg = "https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2Ffinolex-house-wire.jpeg?alt=media&token=49a8b4ef-6acc-4879-b435-cd1b7630d0e1";
let pannelImg = "https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2Fled-slim-panel-light.jpeg?alt=media&token=3d29fc55-9985-45d5-8d74-7b3339f0d628";
let mcbImg = "https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2Fmcb.png?alt=media&token=90eae995-d568-4c64-a65c-a342635c9b73";
let pipesImg = "https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2Fpipes.jpg?alt=media&token=6123bac7-ae11-42a8-94a6-766723ae932d";
let immersionImg = "https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2Felectric-immersion-rod.png?alt=media&token=ac527af6-8cb6-4813-9c60-9eee87a44314";

let renderItem = (icon, text) =>
  <div className="slider-main-container">
    <img className="slider-img-container" src={icon} />
    <div className="slider-text-container">
      <p>{text}</p>
    </div>
  </div>


export default () =>
  <div id="products" className="products-main-container">
    <div className="products-text-container">
      <p>Everything You Need. And More</p>
    </div>
    <div className="slider-default-container">

      <div className="slider-container">
        {renderItem(pipesImg, "Electric Pipes")}
        {renderItem(pannelImg, "L.E.D Pannels")}
        {renderItem(wireImg, "Wires & Cables")}
        {renderItem(mcbImg, "M.C.B")}
        {renderItem(immersionImg, "Immersion Rod")}
        {/* {this.renderItem(pannelImg, "L.E.D Pannels")} */}
      </div>
    </div>
  </div>


